<template>
  <div class="home">
    <page-intro />
    <about-project />
    <project-routes />
    <route-authors />
    <people-reviews />
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
    PageIntro: () => import('@/components/blocks/PageIntro.vue'),
    AboutProject: () => import('@/components/blocks/AboutProject.vue'),
    ProjectRoutes: () => import('@/components/blocks/ProjectRoutes.vue'),
    RouteAuthors: () => import('@/components/blocks/RouteAuthors.vue'),
    PeopleReviews: () => import('@/components/blocks/PeopleReviews.vue'),
  }
}
</script>
